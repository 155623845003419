import React from 'react'
import styled from 'styled-components'
import { range } from 'd3-array'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { LABELS_SP63_API, COLORS_SP62_API } from '../../utils/const'
import { group } from 'd3-array'

const Container = styled.div`
  flex: 1;
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: 300px;
  height: 400px;
  margin: 2rem 0 6rem;
  display: flex;
  ${Mq.sm} {
    margin: 2rem 1rem 4rem 0;
  }
`
const GridContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`
const GridLine = styled.div`
  border-bottom: 1px solid var(--lightGray);
  height: 2px;
  width: 100%;
`
const Flex = styled.div`
  display: flex;
  align-items: center;
`
const Tick = styled.div`
  font-size: 1rem;
  color: var(--gray);
  padding-right: 0.5rem;
  width: 2.5rem;
  text-align: right;
`
const BarContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 3rem;
  height: calc(100% - 1.3rem);
  width: calc(100% - 20px);
  // opacity: 0.5;
  // background-color: pink;
  display: flex;
  flex-direction: row;
`
const Bar = styled.div`
  height: 100%;
  width: 20%;
  max-width: 80px;
  margin: 0 20px;
  color: var(--white);
  font-weight: bold;
  //padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`
const Section = styled.div`
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${({ color }) => `var(--${color})`};
  display: flex;

  justify-content: center;
  align-items: center;
`
const SectionLabel = styled.div`
  font-size: 0.75rem;
  color: ${({ color }) => (color ? `var(--${color})` : `var(--white)`)};
`

const BarLabelContainer = styled.div`
  position: absolute;
  height: 5rem;
  bottom: -6rem;
`
const BarLabel = styled.div`
  font-size: ${({ sm }) => (sm ? '.85rem' : '1rem')};
  font-weight: ${({ weight }) => weight};
  color: var(--darkGray);
  //position: absolute;
  //bottom: ${({ position }) => position};
  line-height: 1.2;
  margin: 0 -0.5rem;
  text-align: center;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 1rem;
`
const Box = styled.div`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  background-color: ${({ color }) => `var(--${color})`};
  margin-right: 0.5rem;
`
const BoxLabel = styled.div`
  font-size: 1.2rem;
`
const Legend = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 2rem 0 0 4rem;
  min-width: 270px;
  ${Mq.sm} {
    margin: 2rem 0 4rem 2rem;
  }
`
const Main = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.5rem 0 4rem;
  ${Mq.md} {
    flex-direction: column;
  }
`
const SP63Chart = ({ regions, title }) => {
  const { t } = useTranslation()
  const ticks = range(0, 11)

  // const wrangleData = () => {
  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]

  //       if (!keysToExclude.includes(key) && value !== null) {
  //         const years = key.slice(-8)
  //         const year1 = years.slice(0, 4)
  //         const year2 = years.slice(-4)
  //         const yearWithHyphen = `${year1}-${year2}`
  //         let category
  //         //gsheets plugin makes key start with lower case, so this identifies the beginning of a key
  //         if (key.includes('decrease')) {
  //           category = 1
  //         } else if (key.includes('increase')) {
  //           category = 3
  //         } else category = 2
  //         return { year: yearWithHyphen, category, value }
  //       } else return null
  //     })

  //     .filter((item) => item)
  //     .sort((a, b) => (a.category > b.category ? 1 : -1))

  //   const keyedByYear = allData.reduce((acc, d) => {
  //     if (acc[d.year]) {
  //       acc[d.year].push({
  //         category: d.category,
  //         value: d.value
  //       })
  //     } else {
  //       acc[d.year] = [
  //         {
  //           category: d.category,
  //           value: d.value
  //         }
  //       ]
  //     }
  //     return acc
  //   }, {})

  //   Object.entries(keyedByYear).forEach(([key, item]) => {
  //     const total = item.reduce((acc, curr) => {
  //       return acc + curr.value
  //     }, 0)
  //     keyedByYear[key] = { values: item, total }
  //   })

  //   return keyedByYear
  // }
  const wrangleData = (data) => {
    const dataMap = group(data, (d) => d.YEAR_RANGE ?? d.YEAR)
    const dataByYear = Array.from(dataMap.values())
    return dataByYear.reduce((acc, arr) => {
      acc[arr[0].YEAR_RANGE ?? arr[0].YEAR] = arr
        .sort((a, b) => (a.DIMENSION2 === 'Increased' ? 1 : -1))
        .sort((a, b) => (a.DIMENSION2 === 'Decreased' ? -1 : 1))
      return acc
    }, {})
  }
  const formattedData = wrangleData(regions)
  const calcMaxWidth = (data) => {
    return Math.max(data.length * 100 + 100, 600) + 'px'
  }
  //to ensure correct order:
  const sortedYears = Object.keys(formattedData).sort((a, b) =>
    a > b ? 1 : -1
  )
  const orderedTicks = ticks.reverse()
  return (
    <Main>
      <Container
        maxWidth={calcMaxWidth(Object.keys(formattedData))}
        role="img"
        alt={t(
          'Stacked bar chart showing proportion of countries whose share of national immunization schedule vaccine expenditure funded by domestic government resources increased or remained stable.'
        )}
      >
        <GridContainer>
          {orderedTicks.map((tick, i) => (
            <Flex key={tick}>
              <Tick>{tick * 10}</Tick> <GridLine />
            </Flex>
          ))}
          <BarContainer>
            {sortedYears.map((year, i) => (
              <Bar key={year}>
                {formattedData[year].map((item, j) => {
                  return item.VALUE_NUM ? (
                    <Section
                      key={'a' + j}
                      height={`${item.VALUE_NUM}%`}
                      color={COLORS_SP62_API[item.DIMENSION2]}
                    >
                      <SectionLabel
                        color={item.DIMENSION2 === 'Stable' ? 'black' : ''}
                      >{`${item.VALUE_NUM.toFixed()}%`}</SectionLabel>
                    </Section>
                  ) : null
                })}
                <BarLabelContainer>
                  <BarLabel weight={year === '2018-2019' ? 'bold' : 'normal'}>
                    {year === '2018-2019' ? t('Baseline') : year}
                  </BarLabel>
                </BarLabelContainer>
                {/* <BarLabel position={'-52px'} weight={'normal'} sm={true}>
                  {`n=${formattedData[year].total}`}
                </BarLabel> */}
                {/* {!i && (
                  <BarLabel key={'baseline'} position={'-55px'} weight={'bold'}>
                    {t('Baseline')}
                  </BarLabel>
                )} */}
              </Bar>
            ))}
          </BarContainer>
        </GridContainer>
      </Container>
      <Legend>
        {Object.entries(COLORS_SP62_API).map(([key, value]) => (
          <Row key={value}>
            <Box color={value}></Box> {'='}&nbsp;
            <BoxLabel>{t(LABELS_SP63_API[key])}</BoxLabel>
          </Row>
        ))}
      </Legend>
    </Main>
  )
}

export default SP63Chart
